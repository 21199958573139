import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/enterprise.css";
import { Fade } from "react-reveal";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import { Helmet } from "react-helmet";

const ArticlesSingle = () => {
  const { title } = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+"/blog")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        console.log(data)
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);


  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }
  const blogPost = data && data.find(
    (blog) =>
      removeTurkishChars(blog.title).replace(/\s+/g, "-").toLowerCase() ===
      title
  );

  if (!blogPost) {
    return <p>Blog post not found</p>;
  }
  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{blogPost.title} | Deka Solar</title>
        <meta
          name="description"
          content={blogPost.desc}
        />
        <link
          rel="canonical"
          href={`https://www.dekasolar.com/makaleler/${removeTurkishChars(blogPost.title)
            .replace(/\s+/g, "-")
            .toLowerCase()}`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div>
            <div className="container enterprise-main d-flex justify-content-center align-items-center">
              <div className="bg-light container inner-container bg-articles d-flex justify-content-center align-items-center p-0">
                <div className="col-12  text-start h-100 d-flex align-items-end">
                  <div className="col-lg-10 col-12 d-flex text-black align-items-center text-white p-4">
                    <div className="card-type-four bg-articles-card main-radius  d-flex">
                      <div className=" d-flex justify-content-center flex-column p-3">
                        <h1>
                          <b>{blogPost.title}</b>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container col-12 p-3 d-flex flex-column justify-content-start align-items-start">
              <p className="mb-3 text-start">{blogPost.desc}</p>
              <a href={blogPost.pdf}>
                <button className="button-type-five mb-3">daha fazla</button>
              </a>
              <Footer />
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ArticlesSingle;
