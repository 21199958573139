import React, { useEffect, useState } from "react";
import Enterprise from "./Enterprise";
import Services from "./Services";
import Experience from "./Experience";
import Articles from "./Articles";
import FAQ from "./FAQ";
import Contact from "./Contact";
import Footer from "../wrappers/Footer";
import { Fade } from "react-reveal";
import Counter from "./Counter";
import {Helmet} from "react-helmet";

const HomeComponent = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);
  return (
    <>
    <Helmet>
        <title>Deka Solar - Energizes You</title>
        <meta name="description" content="DEKA Enerji, enerji sektöründe lider bir konumda yer almayı hedefleyen bir vizyonla hareket etmektedir."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
      <div className="downing-page-div">
      <div className="gap-3 d-md-grid">
        <Enterprise/>
        {/* seo icin  */}
        <h1 className="d-none">Deka Solar - Energizes You</h1>
        <h2 className="d-none">DEKA Enerji, enerji sektöründe lider bir konumda yer almayı hedefleyen bir vizyonla hareket etmektedir.</h2>
         {/* seo icin  */}
        <div className="mt-lg-5 mt-3"><Services /></div>
        <div className="mt-lg-5 mt-3">  <Experience /></div>
        <div className="mt-lg-5 mt-3">  <Counter /></div>
        <div className="mt-lg-5 mt-3"> <Articles /></div>
        <div className="mt-lg-5 mt-3"><FAQ /></div>
        <div className="mt-lg-5 mt-3"> <Contact /></div>
     
        
      
       
        
       
        <div className="container p-lg-0 mt-lg-5 mt-3">
          {" "}
          <Footer />
        </div>
      </div>
      </div>
      </Fade>
    </>
  );
};

export default HomeComponent;
