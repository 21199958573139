import React, { useState,useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import "../assets/css/mobileMenu.css";
import logo from "../assets/images/deka_branding-06.png"
import pattern from "../assets/images/mobil_logo_2.png"

const MobileMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    setMenuOpen(false);
  }, []); 


  return (
    <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
      <div className={`toggle-container ${isMenuOpen ? "white" : ""}`} onClick={toggleMenu}>
        <div className="toggle">
          {isMenuOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
        </div>
      </div>
      <div className={`menu-content ${isMenuOpen ? "open" : ""}`}> 
        <div className="w-100 bg-deka">
          <img style={{height:"10vh"}} src={logo} alt="logo" />
          <div className="container mobile-inner-section w-100">
            <div className="col-12 d-flex">
              <div className="col-6 d-flex align-items-center justify-content-center">
                <ul>
                <li><a href="/">Anasayfa</a></li>
                  <li><a href="/kurumsal">Kurumsal</a></li>
                  <li><a href="/hizmetlerimiz">Hizmetlerimiz</a></li>
                  <li><a href="/deneyimlerimiz">Deneyim</a></li>
                  <li><a href="/makaleler">Makaleler</a></li>
                  <li><a href="/sikca-sorulan-sorular">SSS</a></li>
                  <li><a href="/iletisim">İletişim</a></li>
                </ul>
              </div>
              <div className="col-6 d-flex align-items-center">
                <img src={pattern} alt="pattern" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
