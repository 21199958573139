import React, { useEffect, useState } from "react";
import "../assets/css/enterprise.css";
import { Fade } from "react-reveal";
import img1 from "../assets/images/img-6.png";
import img2 from "../assets/images/img-7.png";
import img3 from "../assets/images/img-dekabot.png";
import Footer from "../wrappers/Footer";
import Header from "../wrappers/Header";
import {Helmet} from "react-helmet";

const EnterpriseMain = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  return (
    <>
     <Helmet>
        <title>Kurumsal | Deka Solar</title>
        <meta name="description" content="Mühendislik disiplinlerinde uzmanlaşmış bir ekip tarafından kurulmuş olup, güneş enerji santralleri sektöründe kapsamlı mühendislik-danışmanlık hizmetleri sunmaktayız."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/kurumsal"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>

      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="d-flex justify-content-center">
            <div className="container enterprise-main-inner justify-content-center align-items-center">
              <div className="container inner-container bg-enterprise-main d-flex justify-content-center align-items-center p-0">
                <div className="col-12  text-start h-100 d-flex align-items-end justify-content-center flex-lg-row flex-column">
                  <div className="col-lg-5 col-12 text-white  d-flex flex-column justify-content-end p-3 ">
                    <h1>
                      <b>Kurumsal</b>
                    </h1>
                    <h3>
                      <b>deka enerji,</b>
                    </h3>{" "}
                    <p>
                      mühendislik disiplinlerinde uzmanlaşmış bir ekip
                      tarafından kurulmuş olup, güneş enerji santralleri
                      sektöründe kapsamlı mühendislik-danışmanlık hizmetleri
                      sunmaktadır. Elektrik mühendisliği, inşaat mühendisliği ve
                      endüstri mühendisliği gibi farklı alanlardaki
                      uzmanlarımız, sektörün hem mevzuatsal hem de teknik
                      yönlerine hakimdir. İşveren mühendisliği, fizibilite
                      raporu hazırlama, santral değerleme, bakım-onarım, test,
                      denetim ve araç şarj istasyonu gibi alanlarda sunduğumuz
                      hizmetlerle müşterilerimize kapsamlı çözümler sunmaktayız.
                    </p>
                  </div>
                  <div className="col-lg-7 col-12 text-white d-lg-flex  justify-content-center p-lg-3  align-items-end">
                    <div className="col-lg-6 col-12">
                      <div className="card-type">
                        <h3>
                          <b>Vizyon</b>
                        </h3>
                        <p>
                          DEKA Enerji, enerji sektöründe lider bir konumda yer
                          almayı hedefleyen bir vizyonla hareket etmektedir.
                          Vizyonumuz, sadece Türkiye’de değil, uluslararası
                          arenada da tanınan bir marka olmak ve sürdürülebilir
                          enerji projeleriyle dünya çapında etkileşimde
                          bulunarak gelecek nesiller için temiz bir enerji
                          geleceği inşa etmektir.
                        </p>
                      </div>
                    </div>{" "}
                    <div className="col-lg-6 col-12">
                      <div className="card-type">
                        <h3>
                          <b>Misyon</b>
                        </h3>
                        <p>
                          DEKA Enerji, enerji sektöründe mükemmeliyeti
                          hedefleyen bir mühendislik ve danışmanlık firması
                          olarak, sürdürülebilir ve yenilenebilir enerji
                          çözümlerini öncü bir yaklaşımla sunmayı misyon
                          edinmiştir. Misyonumuz, müşteri ihtiyaçlarına
                          odaklanarak, yenilikçi teknolojileri ve mühendislik
                          bilgisini birleştirerek, çevresel etkileri en aza
                          indirgeyerek enerji sektörüne değer katmaktır.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container col-12">
            <div className="d-lg-flex gap-2 justify-content-center">
              <div className="col-lg-4 col-12 mt-3 mt-lg-0">
                <div className="card-type-two text-start d-flex flex-column">
                  <img src={img1} alt="deka solar enerji"></img>
                  <div className="p-2 ">
                    {" "}
                    <h5>
                      <b>Teknoloji Alanında Lider</b>
                    </h5>
                    <p>
                      DEKA Enerji, teknoloji alanında öncü bir konumda
                      bulunarak, termal ve elektrolüminesans (EL) ölçümlerini
                      otonom bir şekilde gerçekleştirebilen yapay zeka tabanlı
                      dekaX yazılımını geliştirme çalışmalarına başlamıştır.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 mt-3 mt-lg-0">
                <div className="card-type-two text-start d-flex flex-column">
                  <img src={img2} alt="deka solar enerji"></img>
                  <div className="p-2">
                    {" "}
                    <h5>
                      <b>Raporlama ve Yapay Zeka</b>
                    </h5>
                    <p>
                      DEKA Enerji’nin inovasyon çabaları, termal ve EL
                      ölçümlerinin yanı sıra raporlama süreçlerini büyük ölçüde
                      hızlandıracak olan dekaX yazılımına odaklanmaktadır.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-lg-4 col-12 mt-3 mt-lg-0">
                <div className="card-type-two text-start d-flex flex-column">
                  <img src={img3} alt="deka solar enerji"></img>
                  <div className="p-2">
                    {" "}
                    <h5>
                      <b>Dekabot</b>
                    </h5>
                    <p>
                      DEKA Enerji, müşteri etkileşimini artırmak amacıyla
                      yenilenebilir enerji sektöründe bir ilki gerçekleştirerek,
                      web sitesinde hizmete sunduğu dekaBOT ile yapay zeka
                      tabanlı sohbet robotunu müşterilerinin kullanımına
                      sunmuştur.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap mt-3 mb-3 p-2">
              <p className="p-0 m-0 text-start">
                DEKA Enerji olarak, enerji sektöründeki başarımızı sürdürerek,
                <br />
                müşterilerimize en iyi hizmeti sunmaya devam ediyoruz.
              </p>
            </div>
            <Footer className="mt-5" />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default EnterpriseMain;
