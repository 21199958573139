import React, { useState, useEffect, useRef } from "react";
import Header from "../wrappers/Header";
import "../assets/css/contact.css";
import Footer from "../wrappers/Footer";
import { Fade } from "react-reveal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import {Helmet} from "react-helmet";
import ReactHtmlParser from "react-html-parser";
const ContactMain = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 2500);
  }, []);

  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isButtonDisabled) {
      setIsButtonDisabled(true);

      const encryptedText = CryptoJS.AES.encrypt(
        uuidv4(),
        process.env.REACT_APP_SSK
      ).toString();

      const FD = new FormData(form.current);
      FD.append("accessToken", encryptedText);

      const obj = {
        message: FD.get("message"),
        userInfo: FD.get("userInfo"),
        from: FD.get("from"),
        subject: FD.get("subject"),
        tel: FD.get("tel"),
        ownerName: "infodekasolar@gmail.com",
        to: "info@dekasolar.com",
        host: "smtp.gmail.com",
        service: "gmail",
        accessToken: encryptedText,
      };
      try {
        const res = await axios.post(
          "https://nodemailer.3wweb.org/",
          obj,
          {
            auth: {
              username: "OSICrew_Node_Mailler",
              password: "1786oSi?173",
            },
          }
        );
        if (res) {
          setIsSuccess(true);
          toast.success("Mesajınız gönderildi.", { autoClose: 4500 });
          console.log(res);
        }
      } catch (error) {
        console.log(error);
        console.log(obj);
        toast.error(error.message, { autoClose: 4500 });
      }

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+"/contact")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>İletişim | Deka</title>
        <meta name="description" content="İletişim sayfamızdan bize ulaşın! Sorularınızı, önerilerinizi ve işbirliği taleplerinizi bekliyoruz. Hızlı ve kolay iletişim için buradayız."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/iletisim"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <ToastContainer />
          <div className="container contact-page">
            <div className="col-12 d-lg-flex mb-3 p-lg-1 p-2 gap-3 justify-content-center">
              <div className="col-lg-6 col-12 d-flex justify-content-start flex-column">
                <h5 className="p-0 m-0 text-start">
                  <b>İletişim</b>
                </h5>
                <div className="d-flex mt-3">
                  <div className="col-4 text-start ">
                    <h6 className="p-0 m-0">
                      <b>{data[0]?.title ? ReactHtmlParser(data[0].title) : "İçerik bulunamadı."}</b>
                    </h6>

                    <p>
                    <a aria-label="phone" href={`tel:${data[0]?.desc}`}>
                    {data[0]?.desc ? ReactHtmlParser(data[0].desc) : "İçerik bulunamadı."}
                  </a>
                    </p>
                  </div>
                  <div className="col-4 text-start ">
                    <h6 className="p-0 m-0">
                      <b>{data[2]?.title ? ReactHtmlParser(data[2].title) : "İçerik bulunamadı."}</b>
                    </h6>
                    <p>
                    <a aria-label="mail" href={`mailto:${data[2]?.desc}`}>
                    {data[2]?.desc ? ReactHtmlParser(data[2].desc) : "İçerik bulunamadı."}
                  </a>                    </p>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="col-4 text-start ">
                    <h6 className="m-0 p-0">
                      <b>{data[1]?.title ? ReactHtmlParser(data[1].title) : "İçerik bulunamadı."}</b>
                    </h6>
                    <p>
                    {data[1]?.desc ? ReactHtmlParser(data[1].desc) : "İçerik bulunamadı."}
                    </p>
                  </div>
                </div>

                <div className="bg-white main-radius p-4 mt-3 ">
                  <form id="contact-form" ref={form} onSubmit={handleSubmit}>
                    <div className="d-flex gap-3 justify-content-center">
                      <div className="col-6 d-flex justify-content-start flex-column p-2">
                        <input placeholder="İsim" type="text" name="userInfo" />
                        <input
                          className="mt-2"
                          placeholder="Email"
                          type="text"
                          name="from"
                        />
                      </div>
                      <div className="col-6 col-6 d-flex justify-content-start flex-column p-2">
                        <input
                          className="mt-2"
                          placeholder="Telefon"
                          type="text"
                          name="tel"
                        />{" "}
                        <input placeholder="Konu" type="text" name="subject" />
                      </div>
                    </div>
                    <div className="col-12 col-6 d-flex justify-content-start mt-2 ">
                      <textarea
                        className="main-radius bg-light border-none w-100"
                        name="message"
                        rows="4"
                        cols="50"
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-start mt-2">
                      <button
                        className="button-type"
                        disabled={isButtonDisabled}
                      >
                        gönder
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-12 m-0 mt-3">
                <iframe
                  className="map-inner-main"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.9436209968617!2d29.961673275866307!3d40.76326473447591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb4f7cc645272f%3A0xa6d17877e2360416!2sDeka%20Solar%20Enerji%20A.%C5%9E!5e0!3m2!1str!2str!4v1708947878180!5m2!1str!2str"
                  allowFullScreen
                  loading="lazy"
                  title="map"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ContactMain;
