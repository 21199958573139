import React, { useState, useEffect } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import "../assets/css/faq.css";
import { RxCross2 } from "react-icons/rx";
// Style components using Tailwind CSS
import ChatHistory from "../chat/ChatHistory";
import Loading from "../chat/Loading";
import { CiLocationArrow1 } from "react-icons/ci";

const App = () => {
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // inislize your Gemeni Api
  const genAI = new GoogleGenerativeAI(
    "AIzaSyDEJ7RU2SWcujYPkiEjGRr72BQ-sZ5DWLw"
  );
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  // Function to handle user input
  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  // Function to send user message to Gemini
  const sendMessage = async () => {
    if (userInput.trim() === "") return;

    setIsLoading(true);
    try {
      // call Gemini Api to get a response
      const result = await model.generateContent(userInput);
      const response = await result.response;
      console.log(response);
      // add Gemeni's response to the chat history
      setChatHistory([
        ...chatHistory,
        { type: "user", message: userInput },
        { type: "bot", message: response.text() },
      ]);
    } catch {
      console.error("Error sending message");
    } finally {
      setUserInput("");
      setIsLoading(false);
    }
  };

  // Function to clear the chat history
  const clearChat = () => {
    setChatHistory([]);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="chat-container rounded-lg shadow-md p-4"
              style={{ height: chatHistory.length > 0 ? "30vh" : "10vh" }}
>
        <ChatHistory chatHistory={chatHistory} />
        <Loading isLoading={isLoading} />
      </div>

      <div className="col-12 mb-3" style={{ position: "relative" }}>
        <input
          className=" w-100 main-radius p-2 faq-input"
          type="text"
          placeholder="Sorunuzu giriniz.."
          value={userInput}
          onChange={handleUserInput}
        />
        <div className="send-icon" onClick={sendMessage} disabled={isLoading}>
          <CiLocationArrow1 />
        </div>
      </div>

      {chatHistory.length > 0 && (
  <button className="button-type p-2  mb-3" onClick={clearChat}>
    Sohbeti Temizle
  </button>
)}

    </div>
  );
};

export default App;
