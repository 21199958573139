import React, { useState, useEffect, useRef } from "react";
import "../assets/css/counter.css";
import icon1 from "../assets/images/solar_icon.svg";
import icon2 from "../assets/images/location_icon.svg";
import icon3 from "../assets/images/charge_icon.svg";
import ReactHtmlParser from "react-html-parser";

const Counter = () => {
  const [solarPower, setSolarPower] = useState(0);
  const [provinceCount, setProvinceCount] = useState(0);
  const [chargingStations, setChargingStations] = useState(0);
  const counterRef = useRef(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+"/sayac")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
        console.log(data)
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const startCounters = () => {
      const solarInterval = setInterval(() => {
        if (solarPower >= parseInt(data[0]?.text)) {
          clearInterval(solarInterval);
        } else {
          setSolarPower(prev => Math.min(prev + 1, parseInt(data[0]?.text)));
        }
      }, 150);

      const provinceInterval = setInterval(() => {
        if (provinceCount >= parseInt(data[1]?.text)) {
          clearInterval(provinceInterval);
        } else {
          setProvinceCount(prev => Math.min(prev + 1, parseInt(data[1]?.text)));
        }
      }, 150);

      const chargingInterval = setInterval(() => {
        if (chargingStations >= parseInt(data[2]?.text)) {
          clearInterval(chargingInterval);
        } else {
          setChargingStations(prev => Math.min(prev + 1, parseInt(data[2]?.text)));
        }
      }, 150);

      return () => {
        clearInterval(solarInterval);
        clearInterval(provinceInterval);
        clearInterval(chargingInterval);
      };
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          startCounters();
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 } // Intersection observer threshold
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [data, solarPower, provinceCount, chargingStations]);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div
      className="container d-lg-flex d-md-flex d-grid justify-content-center gap-lg-5 gap-3 counter-page mt-lg-0 mt-5"
      ref={counterRef}
    >
      <div className="col-lg-2 col-md-3 col-10 counter-item m-4">
        <div className="img-radius-counter">
          <img src={icon1} className="counter-img" alt="solar-icon" />
        </div>
        <p className="mt-lg-4 mt-2">güneş santrali</p>
        <p className="counter-title">
          <b>{solarPower}.19</b>
        </p>
        <p>mw</p>
      </div>
      <div className="col-lg-2 col-md-3 col-10 counter-item m-4">
        <div className="img-radius-counter">
          <img src={icon2} className="counter-img" alt="location-icon" />
        </div>
        <p className="mt-lg-4 mt-2">türkiye'nin her ilinde</p>
        <p className="counter-title">
          <b>{provinceCount}</b>
        </p>
        <p>il</p>
      </div>
      <div className="col-lg-2 col-md-3 col-10 counter-item m-4">
        <div className="img-radius-counter">
          <img src={icon3} className="counter-img" alt="charge-icon" />
        </div>
        <p className="mt-lg-4 mt-2">sarj istasyon ağı</p>
        <p className="counter-title">
          <b>{chargingStations}</b>
        </p>
        <p>soket</p>
      </div>
    </div>
  );
};

export default Counter;
