import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/enterprise.css";
import { Fade } from "react-reveal";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Helmet } from "react-helmet";

const ExperienceSingleMain = () => {
  const { title } = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 2500);
  }, []);

  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+"/experience")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const expPost =
    data &&
    data.find(
      (experience) =>
        removeTurkishChars(experience.title)
          .replace(/\s+/g, "-")
          .toLowerCase() === title
    );

  if (!expPost) {
    return <p>Experience not found</p>;
  }

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex);
  };

  return (
    <>
      <Helmet>
        <title>
          {typeof expPost.title === "string" ? expPost.title : ""} | Deka Solar{" "}
        </title>
        <meta
          name="description"
          content={typeof expPost.desc_seo === "string" ? expPost.desc_seo : ""}
        />
        <link
          rel="canonical"
          href={`https://www.dekasolar.com/deneyimlerimiz/${removeTurkishChars(
            expPost.title
          )
            .replace(/\s+/g, "-")
            .toLowerCase()}`}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div>
            <h1 className="d-none">
              <b>{expPost.title}</b>
            </h1>
            <div className="container d-flex justify-content-center align-items-center">
              <div className="experince-single container d-flex justify-content-center align-items-center p-0">
                <div className="col-lg-11 col-12 d-flex align-items-center justify-content-center flex-column">
                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    loop={true}
                    onSlideChange={handleSlideChange}
                  >
                    {expPost.projects.map((project, index) => (
                      <SwiperSlide key={project._id}>
                        <div className="d-flex justify-content-center align-items-center flex-column container">
                          <div className="col-lg-10 col-12">
                            <img
  src={process.env.REACT_APP_API_URL + `/uploads/${project.project_img}`}                            
                              alt={`experience-${index}`}
                            />
                          </div>
                          <div className="col-12">
                            <h4 className="text-start mt-3 mb-3">
                              <b>{expPost.title}</b>
                            </h4>
                            <div className="d-flex">
                              <div className="col-6 text-start">
                                <p>
                                  <b>{expPost.title_inner}</b>
                                </p>
                                <p>{project.location}</p>
                              </div>
                              <div className="col-6 text-start">
                                <p>
                                  <b>{expPost.title_inner_2}</b>
                                </p>
                                <p>{project.type}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
            <div className="container col-12 p-3">
              <Footer />
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ExperienceSingleMain;
