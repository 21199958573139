import React, { useEffect, useRef, useState } from "react";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import "../assets/css/faq.css";
import logo from "../assets/images/deka_logo.png";
import logo_faq from "../assets/images/faq-icon.png";
import { CiLocationArrow1 } from "react-icons/ci";
import axios from "axios";
import { FaRegUser } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import App from "./App";

const FAQMain = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/faq")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  const faqs = data;

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Sıkça Sorulan Sorular | Deka Solar</title>
        <meta
          name="description"
          content="Merak ettiklerinize hızlıca cevap bulun ya da DEKABOT’a sorun! Sorularınızı bizimle paylaşın, size en uygun enerji çözümleri konusunda sizlere yardımcı olalım."
        />
        <link
          rel="canonical"
          href="https://www.dekasolar.com/sikca-sorulan-sorular"
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="d-flex justify-content-center">
            <div className="container">
              <div className="col-12 d-lg-flex gap-2 justify-content-center">
                <div className="col-lg-6 col-12 sss-bg-inner main-radius d-flex align-items-end ml-2">
                  <div className=" col-12 text-white text-start d-flex flex-column p-3 mt-5">
                    <h1>
                      <b>
                        Sıkça Sorulan <br />
                        Sorular
                      </b>
                    </h1>

                    <p>
                      Merak ettiklerinize hızlıca cevap bulun ya da DEKABOT’a
                      sorun! Sorularınızı bizimle paylaşın, size en uygun enerji
                      çözümleri konusunda sizlere yardımcı olalım.
                    </p>
                  </div>
                </div>

                <div
                  className="col-lg-6 col-12 bg-white main-radius h-100 mt-lg-0 mt-3"
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <div className="col-12  d-flex align-items-center justify-content-center flex-column faq_help">
                    <img className="w-20 mt-5" src={logo} alt="Logo"></img>
                    <p className="mt-5">
                      Bugün size nasıl yardımcı olabiliriz?
                    </p>
                  </div>

                  <App />
                </div>
              </div>
              <div className="col-12 bg-white mt-3 text-start p-3 main-radius mb-3">
                {faqs.map((faq) => (
                  <div key={faq.faq_no}>
                    <h5>
                      <b>{faq.title}</b>
                    </h5>
                    <p>{faq.desc}</p>
                  </div>
                ))}
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default FAQMain;
