import React, { useEffect, useState } from "react";
import "../../assets/css/enterprise.css";
import "../../assets/css/services.css";
import { Fade } from "react-reveal";
import Footer from "../../wrappers/Footer";
import Header from "../../wrappers/Header";
import img1 from "../../assets/images/img-k.png";
import {Helmet} from "react-helmet";

const Santral = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  return (
    <>
      <Helmet>
        <title>Santral Kıymetlendirme | Deka Solar
</title>
        <meta name="description" content="Unleashing a power-packed digital experience."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/hizmetlerimiz/santral-kiymetlendirme"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="container justify-content-center align-items-center services-main services-inner">
            <div className="bg-light container inner-container bg-services d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h_160 mt-5">
                <div className="col-lg-9 col-12 text-white d-flex flex-column justify-content-end p-3 mt-5 h_38">
                  <h1>
                    <b>Hizmetlerimiz</b>
                  </h1>
                  <h5>
                    <b>deka enerji olarak,</b>
                  </h5>{" "}
                  <p>
                    Enerji sektöründeki müşterilerimize kapsamlı hizmetler
                    sunarak projelerinin her aşamasında güvenilir bir ortak
                    oluyoruz.
                  </p>
                </div>
                <div className="col-12 d-flex align-items-end flex-wrap p-3">
                  <div className="service-cards d-flex flex-wrap  gap-2 w-100 justify-content-between">
                    <div className="services_each_card col-lg-2 col-5 p-3">
                      <h5>İşveren Mühendisliği</h5>

                      <a
                        target="_blank "
                        href="/hizmetlerimiz/isveren-muhendisligi"
                      >
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Fizibilite Raporu Hazılanması</h5>

                      <a
                        target="_blank"
                        href="/hizmetlerimiz/fizibilite-raporu-hazilanmasi"
                      >
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Denetim ve Sertifikasyon</h5>

                      <a
                        target="_blank"
                        href="/hizmetlerimiz/denetim-ve-sertifikasyon"
                      >
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>İşletme & Bakım</h5>
                      <a
                        target="_blank "
                        href="/hizmetlerimiz/isletme-ve-bakim"
                      >
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Santral Kıymetlendirme</h5>
                      <a
                        target="_blank "
                        href="/hizmetlerimiz/santral-kiymetlendirme"
                      >
                        {" "}
                        <button className="services-inner-button-type-four">
                          daha fazla
                        </button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Araç Şarj İstasyonları</h5>
                      <a
                        target="_blank "
                        href="/hizmetlerimiz/arac-sarj-istasyonlari"
                      >
                        {" "}
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-12">
            <h3 className="d-flex justify-content-start mt-4 mb-4">
              <b> Santral Kıymetlendirme</b>
            </h3>
            <div className="d-lg-flex mt-3 mb-3 p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <img className="w-100" src={img1}></img>
                  <div className="p-2 d-flex justify-content-end flex-column">
                    <p className="m-0 p-0">
                      Güneş Enerjisi Santrallerinin el değiştireceği durumlarda,
                      banka ve sigorta şirketleri tarafından yeniden finanse
                      edileceği zaman ekspertiz işlemleri gerçekleştirilerek
                      yatırım açısından değerlemesi yapılır. Santral
                      Kıymetlendirme kapsamında şu hizmetler sunulmaktadır:
                    </p>
                    <p className="m-0 p-0">
                      &bull; Lisanssız g&uuml;neş enerjisi santrallerinde izin
                      prosed&uuml;rlerinin tam anlamıyla yerine getirilmesi,
                      ileride yaşanabilecek problemlerin &ouml;nlenmesi ve
                      mevzuatsal risklerin belirlenmesi a&ccedil;ısından
                      b&uuml;y&uuml;k &ouml;neme sahiptir. Bu sebeple, tesisin
                      başlangıcından kabul aşamasına kadar olan t&uuml;m izin
                      s&uuml;re&ccedil;lerinin tek tek doğrulanması gerekir.
                      DEKA Enerji olarak, izin aşamalarını yeniden g&ouml;zden
                      ge&ccedil;irerek santralin mevzuatsal yeterliliğini ortaya
                      koymak adına gerekli incelemeri yapıyoruz.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Ge&ccedil;miş d&ouml;nemlerdeki enerji &uuml;retim
                      değeriyle PVSYST sim&uuml;lasyonu sonucu hesaplanan
                      &uuml;retim değerleri karşılaştırılarak beklenen
                      &uuml;retim değerlerinin karşılanıp karşılanmadığı, PR
                      testi sonucu santralin &uuml;retmesi gereken enerjiyi
                      &uuml;retip &uuml;retemediği ve santral harici (enerji
                      nakil hattı, trafo, şebeke kaynaklı kesintiler) enerji
                      kayıplarının tespiti ile santralin ne kadarlık bir
                      bor&ccedil; y&uuml;k&uuml;n&uuml;n altından kalkabileceği
                      ve enerji &uuml;retim kapasitesinin hangi seviyede
                      olduğunu inceliyoruz.
                    </p>
                    <p className="m-0 p-0">
                      &bull; Ger&ccedil;ekleştirilecek saha test ve denetimleri
                      (Termal, Elektrol&uuml;minans, IV-Curve ve AG
                      g&uuml;venlik testleri) ile t&uuml;m santralin ne oranda
                      degradasyona uğradığını belirleyerek degradasyonlu kurulu
                      g&uuml;&ccedil; değerini ve arıza haritasına g&ouml;re
                      yapılacak iyileştirme i&ccedil;in ne kadarlık bir
                      b&uuml;t&ccedil;e ayrılması gerektiğini tespit ediyoruz.
                    </p>
                    <p>
                      &bull; Son olarak; mevzuatsal yeterlilik durumunun, mevcut
                      durumdaki enerji &uuml;retim kapasitesinin, acil yapılacak
                      iyileştirmeler i&ccedil;in ayrılması gereken
                      b&uuml;t&ccedil;e değerinin ve santralin degradasyonlu
                      kurulu g&uuml;&ccedil; değerinin yer aldığı ayrıntılı bir
                      raporu yatırımcıya sunuyoruz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "80px" }}>
              <Footer />
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Santral;
