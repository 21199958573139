import React, { useState, useEffect } from "react";
import "../assets/css/BottomBar.css";
import { a } from "react-router-dom";

const BottomBar = () => {
  const [menu, setMenu] = useState(0); 

  useEffect(() => {
    const routes = [
      { path: "/", menu: 0 }, 
      { path: "/kurumsal", menu: 1 },
      { path: "/hizmetlerimiz", menu: 2 },
      { path: "/deneyimlerimiz", menu: 3 },
      { path: "/makaleler", menu: 4 },
      { path: "/sikca-sorulan-sorular", menu: 5 },
      { path: "/iletisim", menu: 6 },
    ];

    const currentPath = window.location.pathname;

    const matchingRoute = routes.find((route) =>
      currentPath === route.path
    );
    if (currentPath.startsWith("/makaleler")) {
      setMenu(4); 
    } else if (matchingRoute) {
      setMenu(matchingRoute.menu);
    }

    if (currentPath.startsWith("/deneyimlerimiz")) {
      setMenu(3); 
    } else if (matchingRoute) {
      setMenu(matchingRoute.menu);
    }
    if (currentPath.startsWith("/hizmetlerimiz")) {
      setMenu(2); 
    } else if (matchingRoute) {
      setMenu(matchingRoute.menu);
    }
    
    if (matchingRoute) {
      setMenu(matchingRoute.menu);
    }
  }, []);

  return (
    <div>
      <div className="bottom-bar-main d-flex justify-content-center align-items-center">
        <div
          className={`menu ${
            menu === 0
              ? "zero" 
              : menu === 1
              ? "one"
              : menu === 2
              ? "two"
              : menu === 3
              ? "three"
              : menu === 4
              ? "four"
              : menu === 5
              ? "five"
              : menu === 6
              ? "six"
              : ""
          } container d-flex justify-content-between align-items-center mb-4 w-100`}
        >
          
          <div
            className={`menu-item ${menu >= 0 ? "active" : ""}`}
            onClick={() => setMenu(0)}
          >
            <a href="/">anasayfa</a>
          </div>
          <div
            className={`menu-item ${menu >= 1 ? "active" : ""}`}
            onClick={() => setMenu(1)}
          >
            <a href="/kurumsal">kurumsal</a>
          </div>
          <div
            className={`menu-item ${menu >= 2 ? "active" : ""}`}
            onClick={() => setMenu(2)}
          >
            <a href="/hizmetlerimiz">hizmetlerimiz</a>
          </div>
          <div
            className={`menu-item ${menu >= 3 ? "active" : ""}`}
            onClick={() => setMenu(3)}
          >
            <a href="/deneyimlerimiz">deneyimlerimiz</a>
          </div>
          <div
            className={`menu-item ${menu >= 4 ? "active" : ""}`}
            onClick={() => setMenu(4)}
          >
            <a href="/makaleler">makaleler</a>
          </div>
          <div
            className={`menu-item ${menu >= 5 ? "active" : ""}`}
            onClick={() => setMenu(5)}
          >
            <a href="/sikca-sorulan-sorular">sss</a>
          </div>
          <div
            className={`menu-item ${menu === 6 ? "active" : ""}`}
            onClick={() => setMenu(6)}
          >
            <a href="/iletisim">iletişim</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;