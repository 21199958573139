import React from "react";
import logo from "../assets/images/deka_logotype.svg";
import MobileMenu from "./MobileMenu";
import rotate from "../assets/images/rotate.png";
const Header = () => {
  return (
    <>
      {" "}
      <div class="landscape-overlay">
        <p className="text-white">
          Please rotate your device to portrait mode.
        </p>
        <img src={rotate} alt="deka solar enerji"></img>
      </div>{" "}
      <div className="d-flex justify-content-center align-items-center header-main">
        <div className="col-12 d-flex justify-content-center ">
          {" "}
          <a href="/" aria-label="homepage">
            <img className="header-logo" src={logo} alt="header-logo"></img>
          </a>{" "}
        </div>
      </div>
    </>
  );
};

export default Header;
