import React, { useEffect, useState } from "react";
import "../assets/css/enterprise.css";
import { Fade } from "react-reveal";
import noImgTwo from "../assets/images/img-2.png";
import img2 from "../assets/images/img-7.png";
import img3 from "../assets/images/img-4.png";
import img4 from "../assets/images/img.png";
import img5 from "../assets/images/img-1.png";
import img6 from "../assets/images/img-5.png";
import Footer from "../wrappers/Footer";
import Header from "../wrappers/Header";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
const ServicesMain = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [services, setServices] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
      "&": "ve",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ&]/g, (match) => turkishChars[match]);
  }
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+"/services");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setServices(result);
      
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);
  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Hizmetlerimiz | Deka Solar</title>
        <meta
          name="description"
          content="Enerji sektöründeki müşterilerimize kapsamlı hizmetler sunarak projelerinin her aşamasında güvenilir bir ortak oluyoruz."
        />
        <link rel="canonical" href="https://www.dekasolar.com/hizmetlerimiz" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="container   justify-content-center align-items-center services-main">
            <div className="bg-light container inner-container bg-services d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h_160 mt-5">
                <div className="col-lg-9 col-12 text-white d-flex flex-column justify-content-end p-3 mt-5 h_38">
                  <h1>
                    <b>Hizmetlerimiz</b>
                  </h1>
                  <h5>
                    <b>deka enerji olarak,</b>
                  </h5>{" "}
                  <p>
                    Enerji sektöründeki müşterilerimize kapsamlı hizmetler
                    sunarak projelerinin her aşamasında güvenilir bir ortak
                    oluyoruz.
                  </p>
                </div>
                <div className="col-12 d-flex align-items-end flex-wrap p-3">
                  <div className="service-cards d-flex flex-wrap  gap-2 w-100 justify-content-lg-between">
                    {services?.length > 0 &&
                      services.map((service, index) => (
                        <div
                          className="services_each_card col-lg-2 col-5 p-3"
                          key={index}
                        >
                          <h5>{service.title} </h5>

                          <a
                            href={`/hizmetlerimiz/${removeTurkishChars(
                              service.title
                            )
                              .replace(/\s+/g, "-")
                              .replace(/\&+/g, "ve")
                              .toLowerCase()}`}
                          >
                            <button className="button-type-four">
                              daha fazla
                            </button>
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-12 mt-3">
            <div className="d-lg-flex gap-lg-2 justify-content-center ">
              <div className="col-lg-4 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start">
                  <img
                    className="w-100"
                    src={noImgTwo}
                    alt="deka solar enerji"
                  ></img>
                  <div className="p-2">
                    {" "}
                    <h5>
                      <b> {services[0].title
                        ? ReactHtmlParser(services[0].title)
                        : "İçerik bulunamadı."}</b>
                    </h5>
                    <p>
                    {services[0].description
                        ? ReactHtmlParser(services[0].description)
                        : "İçerik bulunamadı."}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start">
                  <img
                    className="w-100"
                    src={img2}
                    alt="deka solar enerji"
                  ></img>
                  <div className="p-2">
                    {" "}
                    <h5>
                      <b>{services[1].title
                        ? ReactHtmlParser(services[1].title)
                        : "İçerik bulunamadı."}</b>
                    </h5>
                    <p>
                    {services[1].description
                        ? ReactHtmlParser(services[1].description)
                        : "İçerik bulunamadı."}
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-lg-4 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start">
                  <img
                    className="w-100"
                    src={img3}
                    alt="deka solar enerji"
                  ></img>
                  <div className="p-2">
                    {" "}
                    <h5>
                      <b>{services[2].title
                        ? ReactHtmlParser(services[2].title)
                        : "İçerik bulunamadı."}</b>
                    </h5>
                    <p>
                    {services[2].description
                        ? ReactHtmlParser(services[2].description)
                        : "İçerik bulunamadı."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex gap-lg-2 justify-content-center mt-3 mb-3">
              <div className="col-lg-6 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-6">
                    <img
                      className="w-100"
                      src={img4}
                      alt="deka solar enerji"
                    ></img>
                  </div>
                  <div className="col-lg-6 p-2 d-flex flex-column justify-content-end">
                    {" "}
                    <h5>
                      <b>{services[3].title
                        ? ReactHtmlParser(services[3].title)
                        : "İçerik bulunamadı."}</b>
                    </h5>
                    <p>
                    {services[3].description
                        ? ReactHtmlParser(services[3].description)
                        : "İçerik bulunamadı."}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 p-lg-0 p-1">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-6">
                    {" "}
                    <img
                      className="w-100"
                      src={img5}
                      alt="deka solar enerji"
                    ></img>
                  </div>
                  <div className="col-lg-6 p-2 d-flex flex-column justify-content-end">
                    {" "}
                    <h5>
                      <b>{services[4].title
                        ? ReactHtmlParser(services[4].title)
                        : "İçerik bulunamadı."}</b>
                    </h5>
                    <p>
                    {services[4].description
                        ? ReactHtmlParser(services[4].description)
                        : "İçerik bulunamadı."}
                    </p>
                  </div>
                </div>
              </div>{" "}
            </div>
            {/* <div className="d-lg-flex mt-3 mb-3 p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-4 d-flex align-items-center">
                    <img
                      className="w-100"
                      src={img6}
                      alt="deka solar enerji"
                    ></img>
                  </div>
                  <div className="col-lg-8 p-2 d-flex justify-content-end flex-column">
                    {" "}
                    <h5 className="mt-5">
                      <b>{services[5]?.title
                        ? ReactHtmlParser(services[5].title)
                        : "İçerik bulunamadı."}</b>
                    </h5>
                    <p>
                    {services[5]?.description
                        ? ReactHtmlParser(services[5].description)
                        : "İçerik bulunamadı."}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <Footer className="mt-5" />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ServicesMain;
