import React,{useState,useEffect} from "react";
import logoImg from "../assets/images/deka_branding-06.png";
import img from "../assets/images/deka_logo.png";
import "../assets/css/notFound.css";
import { Fade } from "react-reveal";
import Header from "../wrappers/Header";

const Notfound = () => {
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setShowLoader(true);
      }, 2500);
    }, []);
  return (
    <>   <div className="loaderes">{showLoader}</div>
     <div className="downing-page-div">
       
    <Fade bottom><div className="not-found d-flex justify-content-center align-items-between">
      <div className="container col-12 d-flex justify-content-center  align-items-center   flex-column">
        <div className="col-3 ">
          <header className="header ">
            <img src={logoImg} alt="Deka energy logo" />
          </header>
        </div>

        <div className="mt-3">
          <img src={img} alt="deka gif" />
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <div className="apps ">
            <>Keep following us on:</>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/dekasolarenergy/"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/dekasolar"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/dekaenerji/"
                ></a>
              </li>
            </ul>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/dekasolarenergy/"
              >
                <button data-text="instagram">
                  <b>instagram</b>
                </button>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com"
              >
                <button data-text="facebook">
                  <b>facebook</b>
                </button>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/dekasolar"
              >
                <button data-text="twitter">
                  <b>twitter</b>
                </button>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/dekaenerji/"
              >
                <button data-text="linkedin">
                  <b>linkedin</b>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div></Fade>
    </div>
    </>
 
  );
};

export default Notfound;
