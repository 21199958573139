import React, { useEffect, useState } from "react";
import "../assets/css/enterprise.css";
import { Fade } from "react-reveal";
import Footer from "../wrappers/Footer";
import Header from "../wrappers/Header";
import Marquee from "react-fast-marquee";
import partnersData from "../data/partners.json";
import osiImage from "../assets/images/osi.svg";
import {Helmet} from "react-helmet";

const ExperienceMain = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);
  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+"/experience")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Deneyimlerimiz | Deka Solar
        </title>
        <meta name="description" content="Müşterilerimizin ihtiyaçlarını karşılamak ve sürdürülebilir bir gelecek için çalışmak, işimizin merkezinde yer almaktadır."/>
        <link
          rel="canonical"
          href="https://www.dekasolar.com/deneyimlerimiz"
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="container  justify-content-center align-items-center">
            <div className=" container inner-container bg-experience d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h-100 mt-5 d-flex align-items-end">
                <div className="col-lg-7 col-12 text-white mt-5 d-flex flex-column justify-content-end p-3 ">
                  <h1>
                    <b>Deneyimlerimiz</b>
                  </h1>
                  <h5>
                    <b>güneşten gelen güç, tecrübemizle aydınlanır</b>
                  </h5>{" "}
                  <p>
                  Deka Enerji, araç şarj istasyonları sektöründe geniş bir deneyim ve birikime sahiptir. Sektörde edinmiş olduğumuz zengin bilgi ve tecrübemizle müşterilerimize öncü ve uzmanlık odaklı hizmet sunuyoruz. Yenilikçi Ar-Ge projeleri ve farklı ölçeklerdeki araç şarj istasyonu projelerinde elde ettiğimiz başarılar, çevre dostu ve etkili enerji çözümleri geliştirmemize olanak sağlamaktadır. Müşterilerimizin ihtiyaçlarını karşılamak ve sürdürülebilir bir gelecek için çalışmak, işimizin merkezinde yer almaktadır.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-12  mt-3 ">
            <div className="d-lg-flex gap-3 mb-3">
              {data.map((experience, index) => (
                <div className="col-xl-4 col-lg-5 col-12" key={experience.exp_no}>
                  <div className="card-type-two text-start d-flex mb-3 mb-lg-0">
                    <img     src={process.env.REACT_APP_API_URL + `/uploads/${experience.img}`}></img>
                
                    <div className="p-2 d-flex justify-content-end flex-column">
                      {" "}
                      <h5>
                        <b>{experience.title} </b>
                      </h5>
                      <a
                        href={`/deneyimlerimiz/${removeTurkishChars(
                          experience.title
                        )
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`}
                      >
                        <button className="button-type">daha fazla</button>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* <Marquee style={{ padding: "10vh" }}>
              {partnersData.partners.map((partner) => (
                <img
                  style={{ height: "15vh" }}
                  key={partner.no}
                  src={osiImage}
                  alt={partner.title}
                />
              ))}
            </Marquee> */}

            <Footer className="mt-5" />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default ExperienceMain;
