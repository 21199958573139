import React,{useState,useEffect} from "react";
import "../assets/css/enterprise.css";
import noImg from "../assets/images/sss.png";
import ScrollToTop from "./ScrollToTop";
import ReactHtmlParser from "react-html-parser";
const FAQ = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+"/home")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data[4]);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
     <div className="scroll-to-top">  <ScrollToTop/></div>
    
        <div className="m-2">
          <div className="container bg-white enterprise-main d-flex justify-content-center align-items-center">
            <div className="bg-light container inner-container bg-faq d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h-100 d-flex align-items-end justify-content-center">
                <div className="col-lg-6 col-12 bg-white d-flex text-black align-items-center faq-sss">
                <div className="col-lg-4 p-lg-4 col-md-4 col-5 p-2">
                    {" "}
                    <img src={noImg} alt="deka solar enerji"></img>
                  </div>
                  <div className="col-lg-7 col-7 col-md-7 p-2">
                    <h5>
                      <b> {data.title
                        ? ReactHtmlParser(data.title)
                        : "İçerik bulunamadı."}</b>
                    </h5>
                    <p>
                    {data.desc
                        ? ReactHtmlParser(data.desc)
                        : "İçerik bulunamadı."}
                    </p>
                    <a href="/sikca-sorulan-sorular" aria-label="faq-libk">
                      {" "}
                      <button className="button-type">dekabot</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
    </>
  );
};

export default FAQ;
