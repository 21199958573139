import React,{useState,useEffect} from "react";
import "../assets/css/enterprise.css";
import noImg from "../assets/images/iletisim.png";
import ScrollToTop from "./ScrollToTop";
import ReactHtmlParser from "react-html-parser";
const Contact = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+"/home")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data[5]);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="scroll-to-top">
        <ScrollToTop />
      </div>

      <div className="d-flex justify-content-center m-2 contact-page">
        <div className="container bg-white enterprise-main d-flex justify-content-center align-items-center">
          <div className="bg-light container inner-container bg-contact d-flex justify-content-center align-items-center p-0">
            <div className="col-12 text-start h-100 d-flex flex-lg-row flex-column justify-space-between">
              <div
                className="col-lg-6 col-12 main-radius  d-flex text-black align-items-end
                 "
              >
                <div className="bg-white d-flex contact-radius">
                  <div className="col-lg-4 p-lg-4 col-5 p-2 d-flex align-items-center">
                    <img src={noImg} alt="deka solar enerji"></img>
                  </div>
                  <div className="col-lg-7 col-7 p-2 d-flex justify-content-center  flex-column">
                    <h5>
                      <b> {data.title
                        ? ReactHtmlParser(data.title)
                        : "İçerik bulunamadı."}</b>
                    </h5>
                    <p>
                    {data.desc
                        ? ReactHtmlParser(data.desc)
                        : "İçerik bulunamadı."}
                    </p>
                    <a href="/iletisim" aria-label="contact-link">
                      <button className="button-type">daha fazla</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className=" w-100 d-flex justify-content-center">
                <div className="col-12 main-radius d-flex text-black justify-content-lg-end justify-content-center align-items-center p-lg-3">
                  <iframe
                    title="myFrame"
                    className="main-radius card-type-four p-3 m-lg-0 m-3 map-main "
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.9436209968617!2d29.961673275866307!3d40.76326473447591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb4f7cc645272f%3A0xa6d17877e2360416!2sDeka%20Solar%20Enerji%20A.%C5%9E!5e0!3m2!1str!2str!4v1708947878180!5m2!1str!2str"
                    width={"90%"}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
