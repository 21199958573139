import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Loader from "./wrappers/Loader";
import BottomBar from "./components/BottomBar";
import EnterpriseMain from "./components/EnterpriseMain";
import ServicesMain from "./components/ServicesMain";
import ExperienceMain from "./components/ExperienceMain";
import ExperienceMainSingle from "./components/ExperienceMainSingle";
import ArticlesMain from "./components/ArticlesMain";
import ArticlesSingle from "./components/ArticlesSingle";
import FAQMain from "./components/FAQMain";
import ContactMain from "./components/ContactMain";
import MobileMenu from "./wrappers/MobileMenu";
import IsverenMuhendisligi from "./components/services/IsverenMuhendisligi";
import Fizibilite from "./components/services/Fizibilite";
import Denetim from "./components/services/Denetim";
import Isletme from "./components/services/Isletme";
import Santral from "./components/services/Santral";
// import AracSarj from "./components/services/AracSarj";
import HomeComponent from "./components/HomeComponent";
import Notfound from "./components/NotFound";

function App() {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 2500);

    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <AppContent showLoader={showLoader} setShowLoader={setShowLoader} />
      </BrowserRouter>
    </div>
  );
}

function AppContent({ showLoader, setShowLoader }) {
 
  const [shouldRenderRoutes, setShouldRenderRoutes] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShouldRenderRoutes(true);
    }, 1700);
  }, []);

  return (
    <>
      <Routes>
        {shouldRenderRoutes && (
          <>
            <Route path="/" element={<HomeComponent />} />
            <Route path="deneyimlerimiz" element={<ExperienceMain />} />
            <Route path="hizmetlerimiz" element={<ServicesMain />} />
            <Route
              path="hizmetlerimiz/isveren-muhendisligi"
              element={<IsverenMuhendisligi />}
            />
            <Route
              path="hizmetlerimiz/fizibilite-raporu-hazilanmasi"
              element={<Fizibilite />}
            />
            <Route
              path="hizmetlerimiz/denetim-ve-sertifikasyon"
              element={<Denetim />}
            />
            <Route
              path="hizmetlerimiz/isletme-ve-bakim"
              element={<Isletme />}
            />
            <Route
              path="hizmetlerimiz/santral-kiymetlendirme"
              element={<Santral />}
            />
            {/* <Route
              path="hizmetlerimiz/arac-sarj-istasyonlari"
              element={<AracSarj />}
            /> */}
            <Route path="kurumsal" element={<EnterpriseMain />} />
            <Route path="sikca-sorulan-sorular" element={<FAQMain />} />
            <Route path="iletisim" element={<ContactMain />} />
            <Route path="/makaleler/:title" element={<ArticlesSingle />} />
            <Route path="/deneyimlerimiz/:title" element={<ExperienceMainSingle />} />
            <Route path="makaleler" element={<ArticlesMain />} />
            <Route path="*" element={<Notfound />} />  
          </>
        )}
      </Routes>

      {!showLoader  && <BottomBar />}
      <MobileMenu />
      {showLoader && <Loader />}
    </>
  );
}

export default App;
