import React, { useEffect,useState } from "react";
import "../assets/css/enterprise.css";
import "../assets/css/experience.css";
import noImg from "../assets/images/deneyim.png";
import { Fade } from "react-reveal";
import Header from "../wrappers/Header";
import Marquee from "react-fast-marquee";
import partnersData from "../data/partners.json"; // Ayarlayın
import osiImage from "../assets/images/osi.svg"; // Import the image
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import ReactHtmlParser from "react-html-parser";

const Experience = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+"/home")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data[2]);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      {" "}
      <div className="scroll-to-top">
        {" "}
        <ScrollToTop />
      </div>
      <div className="d-flex justify-content-center m-2">
        <div className="container bg-white enterprise-main d-flex justify-content-center align-items-center">
          <div className="bg-light container inner-container bg-experience d-flex justify-content-center align-items-center p-0">
            <div className="col-12  text-start h-100">
              <div className="col-lg-6 col-12 bg-white d-flex text-black align-items-center experience-radius">
                <div className="col-lg-4 col-md-4 p-lg-4 col-5 p-2">
                  <img src={noImg} alt="deka solar enerji"></img>
                </div>
                <div className="col-lg-7 col-md-7 col-7 p-2">
                  <h5>
                    <b> {data.title
                        ? ReactHtmlParser(data.title)
                        : "İçerik bulunamadı."}</b>
                  </h5>
                  <p>
                  {data.desc
                        ? ReactHtmlParser(data.desc)
                        : "İçerik bulunamadı."}
                  </p>
                  <a href="/deneyimlerimiz" aria-label="deneyim-link">
                    <button className="button-type">daha fazla</button>
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-12 col-12 text-white d-flex flex-column justify-content-start p-3">
                  <Marquee style={{ padding: "10vh" }}>
                    {partnersData.partners.map((partner) => (
                      <img
                        style={{ height: "15vh" }}
                        key={partner.no}
                        src={osiImage}
                        alt={partner.title}
                      />
                    ))}
                  </Marquee>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
