import React, { useEffect, useState } from "react";
import "../../assets/css/enterprise.css";
import "../../assets/css/services.css";
import { Fade } from "react-reveal";
import Footer from "../../wrappers/Footer";
import Header from "../../wrappers/Header";
import img1 from "../../assets/images/isveren.png";
import img2 from "../../assets/images/hibe.png";
import img6 from "../../assets/images/faq-icon.png";
import { Helmet } from "react-helmet";

const IsverenMuhendisligi = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 2500);
  }, []);

  return (
    <>
      <Helmet>
        <title>İşveren Mühendisliği | Deka Solar</title>
        <meta
          name="description"
          content="Unleashing a power-packed digital experience."
        />
        <link
          rel="canonical"
          href="https://www.dekasolar.com/hizmetlerimiz/isveren-muhendisligi"
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="loaderes">{showLoader}</div>
      <Fade bottom>
        <div className="downing-page-div">
          <Header />
          <div className="container justify-content-center align-items-center services-main services-inner">
            <div className="bg-light container inner-container bg-services d-flex justify-content-center align-items-center p-0">
              <div className="col-12  text-start h_160 mt-5">
                <div className="col-lg-9 col-12 text-white d-flex flex-column justify-content-end p-3 mt-5 h_38">
                  <h1>
                    <b>Hizmetlerimiz</b>
                  </h1>
                  <h5>
                    <b>deka enerji olarak,</b>
                  </h5>
                  <p>
                    Enerji sektöründeki müşterilerimize kapsamlı hizmetler
                    sunarak projelerinin her aşamasında güvenilir bir ortak
                    oluyoruz.
                  </p>
                </div>
                <div className="col-12 d-flex align-items-end flex-wrap p-3">
                  <div className="service-cards d-flex flex-wrap  gap-2 w-100 justify-content-between">
                    <div className="services_each_card col-lg-2 col-5 p-3">
                      <h5>İşveren Mühendisliği</h5>

                      <a href="/hizmetlerimiz/isveren-muhendisligi">
                        <button className="services-inner-button-type-four">
                          daha fazla
                        </button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Fizibilite Raporu Hazılanması</h5>

                      <a href="/hizmetlerimiz/fizibilite-raporu-hazilanmasi">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Denetim ve Sertifikasyon</h5>

                      <a href="/hizmetlerimiz/denetim-ve-sertifikasyon">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>İşletme & Bakım</h5>
                      <a href="/hizmetlerimiz/isletme-ve-bakim">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Santral Kıymetlendirme</h5>
                      <a href="/hizmetlerimiz/santral-kiymetlendirme">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div>
                    {/* <div className="services_each_card col-lg-2 col-5  p-3">
                      <h5>Araç Şarj İstasyonları</h5>
                      <a href="/hizmetlerimiz/arac-sarj-istasyonlari">
                        <button className="button-type-four">daha fazla</button>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container col-12  mt-3">
            <h3 className="d-flex justify-content-start mt-4 mb-4">
              <b> İşveren Mühendisliği</b>
            </h3>
            <div className="d-lg-flex justify-content-between">
              <div className="col-lg-6 col-12 d-flex justify-content-lg-start justify-content-center">
                <div className="col-12 bg-white p-3 main-radius  ">
                  <img
                    className="h-100 main-radius  text-start"
                    src={img1}
                  ></img>
                </div>
              </div>
              <div className="col-lg-6 col-12 d-flex flex-wrap justify-content-center">
                <div className="col-lg-5 col-12 p-lg-0 p-3">
                  <div className="row">
                    <p className="bg-white main-radius p-3 text-start">
                      Kurulması planlanan GES tesisinin finansal ve teknik
                      fizibilitelerinin hazırlanabilmesi için son teknolojili
                      drone’larla saha veya çatının 3D haritalaması çıkarılır ve
                      yerinde detaylı inceleme yapılarak keşif yapılır.
                    </p>
                  </div>
                  <div className="row">
                    <p className="bg-white main-radius p-3 text-start">
                      Yüklenici Firma seçimine dair yapılacak olan ihale için
                      teknik ve idari şartnameler hazırlanır ve aday
                      yükleniciler tarafından sunulacak tekliflerin
                      değerlendirilmesi yapılır. Ayrıca, inşaat sürecinin
                      sorunsuz tamamlanmasını sağlayacak ve yatırımcının
                      menfaatlerini gözetecek kapsamlı bir sözleşme hazırlanır.
                    </p>
                  </div>
                  <div className="row">
                    <p className="bg-white main-radius p-3 text-start">
                      Projenin montaj süreçlerinde, İnşaat İzleme Yazılımları ve
                      DEKA Enerji denetçi ekibi tarafından TS-EN (IEC) 62446
                      standardına göre düzenli denetimler gerçekleştirilerek,
                      inşaatın hem tasarım ve kalite kavramlarına hem de tespit
                      edilecek aksaklıkların hızlı bir şekilde giderilmesi
                      sağlanarak proje takvimine uygun olarak ilerlemesi
                      sağlanır.
                    </p>
                  </div>
                  <div className="row">
                    <p className="bg-white main-radius p-3 text-start m-0">
                      Tesisin işletmeye alınmasından bir süre sonra
                      gerçekleştirilecek final kabul testleri kapsamında yeniden
                      performans değerlendirilmesi yapılarak yatırımcıya bir
                      rapor olarak sunulur.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-12 ms-lg-5 p-lg-0 p-3">
                  <div className="row">
                    <p className="bg-white main-radius p-3 text-start">
                      Yatırımcının KARAR VERME süreçlerini kolaylaştırmak için
                      Yaklaşık Maliyet, Nakit Akışları, ROI, NBD ve LCOE gibi
                      önemli finansal metriklerin yer aldığı Finasal Fizibilite
                      Raporu yatırımcıya sunulur.
                    </p>
                  </div>
                  <div className="row">
                    <p className="bg-white main-radius p-3 text-start">
                      Yüklenici firma tarafından sunulacak projenin ilgili
                      standartlara (TS-EN (IEC) 62548) ve ETKB Yönetmeliklerine
                      uygunluğu incelenerek optimize edilmesi adına görüş ve
                      öneriler yatırımcıya Tasarım İnceleme Raporu olarak
                      sunulur.
                    </p>
                  </div>
                  <div className="row">
                    <p className="bg-white main-radius p-3 text-start">
                      Santral imalatında kullanılacak panel, pano, konstrüksiyon
                      vb. ürünlerin üretim esnasında ve saha tesliminde
                      denetimleri yapılır.
                    </p>
                  </div>
                  <div className="row">
                    <p className="bg-white main-radius p-3 text-start">
                      Kurulumun tamamlanmasından sonra TS-EN (IEC) 62446 ve
                      TS-EN (IEC) 60891 standartlarına göre gerçekleştirilecek
                      devreye alma testleri sonucunda tesisin beklenen
                      performansı gösterip göstermediği tespit edilir ve
                      performans dışı bir problem durumunda garanti süreçleri
                      devreye alınır.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-lg-flex mt-3 mb-3 p-lg-0 p-1">
              <div className="col-12">
                <div className="card-type-two text-start d-lg-flex">
                  <div className="col-lg-3">
                    <img className="w-100" src={img2}></img>
                  </div>

                  <div className="col-lg-9 d-flex justify-content-end flex-column p-2 mt-3">
                    <h5>
                      <div className="d-flex justify-content-start align-items-center">
                        <img className="me-3" src={img6}></img>

                        <b>hibe ve destek fırsatları</b>
                      </div>
                    </h5>
                    <p>
                      Firmamız, enerji sektörüne destek sağlayan 60’tan fazla
                      kurum tarafından hibe ve destek fırsatlarını belirleyerek
                      en avantajlı fırsatları sunuyor. Ayrıca, çözüm ortağımız
                      olan ve 20 ilde 400 farklı proje için destek ve hibe almış
                      profesyonel bir danışmanlık firması aracılığıyla
                      müşterilerimize, bu finansal avantajlardan yararlanmaları
                      konusunda rehberlik ediyoruz. Yararlana bilirlik
                      durumunda, gerekli çalışmaları titizlikle yürüterek
                      sürdürülebilir enerji dönüşümünüzü sağlamaya
                      odaklanıyoruz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Footer className="mt-5" />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default IsverenMuhendisligi;
