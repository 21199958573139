import React from "react";
import logo from "../assets/images/deka_logo.png";


import Fade from "react-reveal/Fade";

const Loader = () => {
  return (
    <Fade bottom duration={1500}>
      <div className="loader">
        <div className="loder-images"><img className="loader-img" src={logo}></img></div>
      </div>
    </Fade>
  );
};

export default Loader;
