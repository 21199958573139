import React, { useState, useEffect } from "react";
import "../assets/css/Footer.css";
import { FaInstagram } from "react-icons/fa";
import { RiFacebookFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import footerLogo from "../assets/images/footerlogo.png";
import ReactHtmlParser from "react-html-parser";

const Footer = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+"/contact")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="col-12 bg-white border-radius-style footer">
        <div className="col-12 p-3">
          <div className="d-flex">
            <div className="card-type-three">
              <a target="_blank" rel="noreferrer" aria-label="instagram" href={data[3]?.desc ? ReactHtmlParser(data[3].desc) : ""}> <FaInstagram /></a>
            </div>
            <div className="card-type-three">
              <a target="_blank" rel="noreferrer" aria-label="facebook" href={data[4]?.desc ? ReactHtmlParser(data[4].desc) : ""}><RiFacebookFill /></a>
            </div>
            <div className="card-type-three">
              <a target="_blank" rel="noreferrer" aria-label="twitter" href={data[6]?.desc ? ReactHtmlParser(data[6].desc) : ""}> <FaXTwitter /></a>
            </div>
            <div className="card-type-three">
              <a target="_blank" rel="noreferrer" aria-label="linkedin" href={data[5]?.desc ? ReactHtmlParser(data[5].desc) : ""}> <FaLinkedinIn /></a>
            </div>
          </div>
        </div>
        <div className="col-12 d-lg-flex">
          <div className="col-lg-6 col-12 p-3 mt-1 d-flex flex-column justify-content-between">
            <div className="d-flex">
              <div className="col-6 text-start">
                <h6>
                  <b>energizes <br /> you.</b>
                </h6>
              </div>
            </div>
            <div className="col-12 d-flex flex-wrap mt-4 footer-main justify-content-center align-items-center">
              <div className="col-lg-3 col-12 text-start">
                <a href="/kurumsal" aria-label="kurumsal-footer">
                  <h6>
                    <b>kurumsal</b>
                  </h6>
                </a>
                <a href="/hizmetlerimiz" aria-label="hizmetlerimiz-footer">
                  <h6>
                    <b>hizmetlerimiz</b>
                  </h6>
                </a>
                <a href="/deneyimlerimiz" aria-label="deneyimlerimiz-footer">
                  <h6>
                    <b>deneyim</b>
                  </h6>
                </a>
              </div>
              <div className="col-lg-3 col-12 text-start">
                <a href="/makaleler" aria-label="makaleler-footer">
                  <h6>
                    <b>makaleler</b>
                  </h6>
                </a>
                <a href="/sikca-sorulan-sorular" aria-label="sss-footer">
                  <h6>
                    <b>sss</b>
                  </h6>
                </a>
                <a href="/iletisim" aria-label="iletisim-footer">
                  <h6>
                    <b>iletişim</b>
                  </h6>
                </a>
              </div>
              <div className="col-lg-3 col-12 text-start">
                <h6 className="p-0 m-0">
                  <b>{data[1]?.title ? ReactHtmlParser(data[1].title) : "İçerik bulunamadı."}</b>
                </h6>
                <p className="me-2">
                  {data[1]?.desc ? ReactHtmlParser(data[1].desc) : "İçerik bulunamadı."}
                </p>
              </div>
              <div className="col-lg-3 col-12 text-start">
                <h6 className="p-0 m-0">
                  <b>{data[0]?.title ? ReactHtmlParser(data[0].title) : "İçerik bulunamadı."}</b>
                </h6>
                <p>
                  <a aria-label="phone" href={`tel:${data[0]?.desc}`}>
                    {data[0]?.desc ? ReactHtmlParser(data[0].desc) : "İçerik bulunamadı."}
                  </a>
                </p>
                <h6 className="p-0 m-0">
                  <b>{data[2]?.title ? ReactHtmlParser(data[2].title) : "İçerik bulunamadı."}</b>
                </h6>
                <p>
                  <a aria-label="mail" href={`mailto:${data[2]?.desc}`}>
                    {data[2]?.desc ? ReactHtmlParser(data[2].desc) : "İçerik bulunamadı."}
                  </a>
                </p>
              </div>
            </div>
            <div className="col-12 madeby_text">
              <div className="d-flex footer-bottom-line">
                <p>made by</p>
                <a aria-label="osi-crew" target="_blank" href="www.osicrew.com">&nbsp; osiCrew</a>
              </div>
              <p className="text-start">copyright © 2023 | osi | all rights reserved.</p>
            </div>
          </div>
          <div className="col-lg-6 col-12 d-flex align-items-end">
            <img src={footerLogo} alt="deka solar enerji"></img>
          </div>
        </div>
      </div>
      <div className="col-12 h_10"></div>
    </>
  );
};

export default Footer;
